





























































































































































import Vue from "vue";
import { ProductLabel } from "@/store/productLabels/types";
import { Utils } from "@/utils/";

export default Vue.extend({
  name: "ProductLabels",

  computed: {
    // both get and set
    _pagination: {
      get: function () {
        return this.$store.state.productLabels.pagination;
      },
      set: function (value) {
        this.$store.dispatch("productLabels/paginate", value);
      },
    },
    loading: function () {
      return this.$store.state.productLabels.loading;
    },
    productLabels: function () {
      return this.$store.state.productLabels.productLabels;
    },
    activeShopToken: function () {
      return this.$store.state.shops.activeShopToken;
    },
  },

  watch: {
    activeShopToken: {
      handler() {
        this.$store.dispatch(
          "productLabels/fetchProductLabels",
          this.showDisabled
        );
      },
      deep: true,
    },
  },

  data: function () {
    return {
      productLabel: {},
      showDisabled: false,

      headers: [
        { text: "Name", value: "name", sortable: false },
        {
          text: "Short Description",
          value: "shortDescription",
          sortable: false,
        },
        { text: "Active", value: "active", sortable: false },
      ],

      dialog: false,
      thisProductLabel: {},
      action: "",
      edit: false,

      snack: false,
      snackColor: "",
      snackText: "",

      searchVal: "",
    };
  },

  mounted: function () {
    // Set the default paging
    this.$store.dispatch("productLabels/paginate", {
      ...this.$store.state.productLabels.pagination,
    });
    this.$store.dispatch(
      "productLabels/fetchProductLabels",
      this.showDisabled
    );
  },

  methods: {
    onShopChange() {
      this.$store.dispatch(
        "productLabels/fetchProductLabels",
        this.showDisabled
      );
    },
    onShowDisabled(isDisabled: boolean) {
      this.$store.dispatch("productLabels/fetchProductLabels", isDisabled);
    },
    onProductLabelAdd() {
      if ((this.$refs.form as any).validate()) {
        this.$store.dispatch(
          "productLabels/createProductLabel",
          this.productLabel
        );

        // reset form
        this.productLabel = {};
        (this.$refs.form as any).reset();
      }
    },
    formatColumn(header: any, item: any) {
      return Utils.formatColumn(header, item);
    },
    confirmDialog(thisProductLabel: ProductLabel, disable: boolean) {
      this.dialog = true;
      this.thisProductLabel = thisProductLabel;
      this.action = disable ? "Disable" : "Enable";
    },
    doAction(thisProductLabel: ProductLabel) {
      this.dialog = false;
      this.$store.dispatch("productLabels/disableProductLabel", {
        label: thisProductLabel,
        showDisabled: this.showDisabled,
      });
    },
    editProductLabel(thisProductLabel: ProductLabel) {
      this.productLabel = thisProductLabel;
      this.edit = true;
    },
    onProductLabelUpdate() {
      if ((this.$refs.form as any).validate()) {
        this.$store.dispatch(
          "productLabels/updateProductLabel",
          this.productLabel
        );

        // reset form
        this.edit = false;
        this.productLabel = {};
        (this.$refs.form as any).reset();
      }
    },
    cancelUpdate() {
      this.edit = false;
      this.productLabel = {};
      (this.$refs.form as any).reset();
      // reload to cancel non committed changes to model
      this.$store.dispatch(
        "productLabels/fetchProductLabels",
        this.showDisabled
      );
    },
    save(item: ProductLabel) {
      this.$store.dispatch("productLabels/updateProductLabel", item);
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
  },
});
